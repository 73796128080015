import GlobalModal from '../components/atoms/GlobalModal'
import { FlashMessage } from '../components/organisms'

export interface IWindow {
  google: any
  location: any
  navigator: any
  globalModal: GlobalModal
  flashMessages: FlashMessage
  onpopstate: any
  confirm: any
  history: any
  dataLayer: any
  rewardful: any
  Rewardful: any
}

export interface IPager {
  current_page: number
  next_page: null | number
  prev_page: null | number
  total_count: number
  total_pages: number
}

export interface IUser {
  admin: boolean
  agent: boolean
  avatar_attached: boolean
  avatar_url: string
  avatar_middle_url?: string
  avatar_large_url?: string
  areas?: IMasterItem[]
  catchphrase: string
  cover_url: string
  bio: string
  created_at: string
  email: string
  fullname: string
  guest: boolean
  guide: boolean
  id: number
  identification_workflow_state?: null | string
  posts_count: number
  review_count?: number
  social_profiles?: string[]
  stripe_customer_id: null | string
  joined_date: string
  unread_notifications_count: number
  updated_at: string
  username: string
  country_code: string
  guide_approved?: boolean
  user_detail?: IUserDetail
  non_member?: boolean
}
export interface IUserDetail {
  display_language: IDisplayLanguage
  display_language_id: number
  created_at: string
  id: number
  bio: string | null
  catchphrase: string | null
  updated_at: string
}
export interface IPostImage {
  created_at: string
  description: null | string
  id: number
  image_url: string
  large_url?: string
  middle_url?: string
  thumb_url?: string
  position: number
  updated_at: string
}

type PartialUser = Partial<IUser>

export interface IMeetingPoint {
  id: number
  location: string
  name: string
  memo: string
  latitude: string
  longitude: string
}

export interface IPostVideo {
  id: number
  name: string
  description: string
  youtube_video_id: string
  position: number
}

export interface IDisplayLanguage {
  code: string
  id: number
  name: string
  name_i18n: string
  name_ja: string
  position: number
}
export interface IPostDetail {
  display_language: IDisplayLanguage
  display_language_id: number
  cancel_policy: string | null
  created_at: string
  highlight: string | null
  id: number
  name: string | null
  included: string | null
  itinerary: string | null
  not_included: string | null
  ogp_description: string | null
  overview: string | null
  post_id: number
  remarks: string | null
  updated_at: string
}
export interface IPost {
  aasm_state_i18n: string
  average_review_score: number
  category_id?: number
  category?: {
    id: number
    name: string
    position: number
  }
  area_id?: number
  city: string
  created_at: string
  description?: string
  full_address: string
  id: number
  latitude: number
  longitude: number
  name: string
  note: string
  post_image?: IPostImage
  post_images?: IPostImage[]
  post_prices?: any[]
  price: string
  public_address: string
  published_at: null | string
  published: boolean
  slug: string
  state: string
  street1: null | string
  street2: null | string
  tags: any[]
  languages: any[]
  user: PartialUser
  user_liked: boolean
  viewed_count: number
  zipcode: null | string
  ogp_description?: string
  post_type: string
  itinerary: string
  overview: string
  included: string
  not_included: string
  remarks: string
  duration: string
  max_people: string
  min_people: string
  tour_group_max_attendees?: number
  meeting_points?: IMeetingPoint[]
  cutoff_times: string
  highlight: string
  cancel_policy: string
  pickup: boolean
  post_start_times?: IPostStartTimes[]
  post_calendars?: any[]
  post_details?: IPostDetail[]
  instant_booking: boolean
  bokun_activity_id?: string
  short_name?: string
  expandable?: boolean
  alert_remaining_number?: number
  alert_number?: number
  cancel_policy_id?: number
}

export interface IPostStartTimes {
  created_at: string
  days: number[]
  end_date: string
  id: number
  max_people: number
  post: any
  start_date: string
  start_time: string
  updated_at: string
}
export interface IPostCalendar {
  available_count: number
  blocked: boolean
  created_at: string
  day: string
  direct_reservations: any[]
  id: number
  is_agent_private_tour: boolean
  max_people: number
  post: IPost
  private_quantity: number | null
  reservations: any[]
  reserved: boolean
  reserved_count: number | null
  reserved_people: number
  start_time: string
  updated_at: string
}

export interface IUserCalendar {
  am: 'available' | 'blocked' | 'maybe' | 'none'
  blocked: boolean
  created_at: string
  day: string
  id: number
  memo: string | null
  pm: 'available' | 'blocked' | 'maybe' | 'none'
  tour_reservations: any[]
  temporary_assignments: any[]
  title: string | null
  updated_at: string
  user: IUser
}

export interface ICustomPost extends IPost {
  size: string
  capacity: string
  functionality: string
  areas: null | number
  private_quantity?: number
  tour_group_max_attendees?: number
  cancel_policy_id?: number
}
export interface ICalendars {
  postCalendars: IPostCalendar[]
  userCalendars: IUserCalendar[]
  tourGuides: ITourGuide[]
}

export interface IDaySchedule {
  am?: 'available' | 'blocked' | 'maybe' | 'none'
  pm?: 'available' | 'blocked' | 'maybe' | 'none'
  blocked?: boolean
  title?: string
  memo?: string
}
export interface IGroupedCalendar extends IDaySchedule {
  id: string
  day: string
  reservations: any[]
  direct_reservations: any[]
  soundouted?: boolean
  requested?: boolean
}

export interface IDayEventsObject {
  reservations: any[]
  direct_reservations: any[]
  soundouted?: boolean
  requested?: boolean
}

export interface ITag {
  id: number
  name: string
  name_ja: string
}

export interface IMasterItem {
  id: number
  name: string
  position: number
}

export interface ISales {
  rates: {
    sales: number
    reservations: string
    profile: number
    reviews: number
  }
  amount: number
  amount_last_month: number
  earning: number
  earning_last_month: number
  tour_group_earning: number
  tour_group_earning_last_month: number
}

export interface IReceipt {
  created_at: string
  guest_name: string
  id: number
  name: string
  no: string
  payment_amount: number
  payment_date: string
  payment_method: string
  reservation_id: number
  reservation_no: string
  tour_date: string
  tour_name: string
  updated_at: string
}

export interface IConversation {
  conversation_type: 'reservation' | 'pre_assign' | 'assign' | 'tour_group'
  id: number
  slug: string
  target_id: number
  target_type: string // "Reservation" and other
  available: boolean
}

export enum TourGuideRequestStatus {
  nothing = 'nothing',
  soundouted = 'soundouted',
  approved = 'approved',
  declined = 'declined',
  requested = 'requested',
  canceled = 'canceled',
}

export interface IReservable {
  assignment_status: 'unassigned' | 'assigned'
  conversation: IConversation
  guest_name: string | null
  id: number
  num_of_adults: number
  num_of_children: number
  num_of_infants: number
  num_of_people?: number
  price: number
  memo?: string
  slug: 'eec0b5a129b53b64'
  thanks_mail_sent_at: string | null
  thanks_mail_sent_count: number
  user: {
    avatar_url: string
    id: number
    username: string
    non_member?: boolean
  }
  workflow_state: string
  workflow_state_enum_value: 'requested' | 'approved' | 'declined' | 'canceled'
  check_in: boolean
}
export interface ITourReservation {
  id: number
  reservable: IReservable
  reservable_id: number
  reservable_type: 'Reservation' | 'DirectReservation'
  tour_group_id: number
  tour_id: number
}

export interface ITourGroupGuide {
  avatar_url: string
  id: number
  username: string
}
export interface ITourGroup {
  guide?: ITourGroupGuide
  id: number
  tour_id: number
  tour_reservations: ITourReservation[]
  conversation: IConversation
  reservation_report: IReservationReport
  reimbursable_cost: number
  subcontract_cost: number
  tour_cost: number
  memo: string
}

export interface ITourGroupPost {
  id: number
  name: string
  is_owner: boolean
  post_image: IPostImage
  user_id: number
  user: IUser
  slug: string
  tour_group_max_attendees?: number
  post_type: string
  private_quantity?: number
  bokun_activity_id?: string
  max_people?: number
}
export interface ITours {
  created_at: string
  day: string
  id: number
  post: ITourGroupPost
  post_id: number
  start_time: string
  start_at?: string
  tour_groups: ITourGroup[]
  unassigned_tour_reservations: ITourReservation[]
  tour_reservations?: ITourReservation[]
  tour_guides?: ITourGuide[]
}
export interface IReservationReport {
  id: number
  reportable_type: string
  reportable_id: number
  status: string
  additional_cost: number
  additional_service: string
  bad_point: string
  created_at: string
  difficult_point: string
  good_point: string
  num_of_people: number
  other: string
  updated_at: string
  user_id: number

  reportable: {
    id: number
    reimbursable_cost: number
    subcontract_cost: number
    tour_cost: number
    memo: string
    tour_id: number
    updated_at: string
    created_at: string
  }
}

export interface IReview {
  body: string
  id: number
  rating: number
  rating_friendliness: number
  rating_hospitality: number
  rating_informative: number
  rating_involved: number
  rating_language: number
  rating_passion: number
  reservation: any
  review_reply: any
  reviewer?: any
  direct_reservation?: any
  reviewer_name?: any
  review_date?: any
  reviewable?: IPost
  reviewable_type?: string
  reviewable_id?: number
  anonymous_review_author?: string
}

export interface IFormalRequestTemplate {
  id: number
  title: string
  mail_subject: string
  mail_text: string
  agent_id: number
}

export interface ISoundOutTemplate {
  id: number
  title: string
  message: string
  agent_id: number
}

export interface IMessage {
  body: string
  conversationId: number
  conversation: IConversation
  created_at: string
  file_attached: boolean
  file_url: null | string
  formatted_sent_time: string
  id: number
  sender: IUser
  sent_at: string
  updated_at: string
  other?: boolean
  tour_guide?: ITourGuide
  tour_guide_message?: ITourGuideMessage
  isSelf?: boolean
}

export interface ITourGuideMessage {
  message?: IMessage
  created_at: string
  tour_guide_id: number
  id: number
  status: 'requested' | 'approved' | 'declined'
  message_id: number
  updated_at: string
}
export interface ITourGuide {
  created_at: string
  guide_id: number
  id: number
  request_status: TourGuideRequestStatus
  request_status_i18n: string
  tour_group_id: number | null
  tour_id: number
  tour_guide_messages?: ITourGuideMessage[]
  tour?: ITours
  guide?: IUser
  updated_at: string
}
